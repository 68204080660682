import React, { useState } from 'react';
import _ from 'lodash';
import { Text } from 'wix-ui-tpa/Text';
import { Divider } from 'wix-ui-tpa/Divider';
import { Checkbox } from 'wix-ui-tpa/Checkbox';
import { MobileDrawer } from 'wix-ui-tpa/MobileDrawer';
import { CheckboxGroup } from 'wix-ui-tpa/CheckboxGroup';
import { Popover, TriggerAction } from 'wix-ui-tpa/Popover';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import { ReactComponent as CaretDown } from 'wix-ui-tpa/dist/src/assets/icons/CaretDown.svg';

import { classes, st } from './Filters.st.css';
import { FiltersDataHooks } from './dataHooks.const';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import { FilterViewModel } from '../../../ViewModel/filterViewModel/filterViewModel';
import {
  WidgetComponents,
  WidgetElements,
} from '../../../../../utils/bi/consts';

type FilterProps = {
  filterViewModel: FilterViewModel;
};

export type FiltersProps = {
  filterViewModels: FilterViewModel[];
};

const Filter: React.FC<FilterProps> = ({ filterViewModel }) => {
  const [open, setOpen] = useState(false);
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const { onFilterChanged, onElementClicked } = useCalendarActions();
  const PopoverElement = Popover.Element || (() => null);
  const PopoverContent = Popover.Content || (() => null);
  const { options, id, label, note } = filterViewModel;

  const getLabel = (): string => {
    const numberOfSelectedOptions = options.filter(({ selected }) => selected)
      .length;
    let value;

    if (numberOfSelectedOptions === 0) {
      value = t('filter.all-options.label');
    } else if (numberOfSelectedOptions > 1) {
      value = String(numberOfSelectedOptions);
    } else {
      value = options.filter(({ selected }) => selected)[0].label;
    }

    return t('filter.cta.label', { label, value });
  };

  const expendFilter = () => {
    onElementClicked(WidgetComponents.FILTER, WidgetElements.FILTER_BUTTON, {
      filterType: id,
    });
    setOpen(!open);
  };

  const toggleOption = (optionID: string) => {
    const option = _.find(options, ({ value }) => value === optionID);
    let newSelectedValues: string[];

    if (option?.selected) {
      newSelectedValues = options
        .filter(({ selected }) => selected)
        .filter(({ value }) => value !== optionID)
        .map(({ value }) => value);
    } else {
      newSelectedValues = options
        .filter(({ selected, value }) => selected || value === optionID)
        .map(({ value }) => value);
    }
    onElementClicked(WidgetComponents.FILTER, WidgetElements.CHECKBOX);
    onFilterChanged(id, newSelectedValues);
  };

  const CTA = () => (
    <div
      data-hook={FiltersDataHooks.FILTER_CTA}
      className={classes.filterWrapper}
    >
      <TextButton
        data-hook={FiltersDataHooks.FILTER_CTA + id}
        className={st(classes.filterCTA, { open })}
        priority={TEXT_BUTTON_PRIORITY.secondary}
        suffixIcon={<CaretDown className={classes.filterCTASuffix} />}
        onClick={expendFilter}
        contentClassName={classes.filterCTAContent}
      >
        {getLabel()}
      </TextButton>
    </div>
  );

  const Content = () => {
    return (
      <>
        <CheckboxGroup className={classes.checkboxGroup}>
          {options.map(({ value, label, selected }) => (
            <Checkbox
              data-hook={FiltersDataHooks.OPTION}
              className={classes.item}
              onChange={() => toggleOption(value)}
              checked={selected}
              key={value}
              label={label}
            />
          ))}
        </CheckboxGroup>
        {note && (
          <>
            <Divider
              data-hook={FiltersDataHooks.NOTE_DIVIDER}
              className={classes.noteDivider}
            />
            <Text data-hook={FiltersDataHooks.NOTE}>{note}</Text>
          </>
        )}
      </>
    );
  };

  return isMobile ? (
    <>
      <CTA />
      <MobileDrawer isOpen={open} onRequestClose={() => setOpen(false)}>
        <div className={classes.mobileContentWrapper}>
          <Text className={classes.mobileContentLabel}>{label}</Text>
          <Content />
        </div>
      </MobileDrawer>
    </>
  ) : (
    <Popover
      shown={open}
      placement="bottom-start"
      dynamicWidth
      triggerAction={TriggerAction.click}
      showArrow={false}
      onClickOutside={() => setOpen(false)}
      className={classes.popover}
    >
      <PopoverElement>
        <CTA />
      </PopoverElement>
      <PopoverContent>
        <Content />
      </PopoverContent>
    </Popover>
  );
};

const Filters: React.FC<FiltersProps> = ({ filterViewModels }) => {
  const { isMobile } = useEnvironment();
  if (filterViewModels.length) {
    return (
      <>
        <Divider
          data-hook={FiltersDataHooks.FILTER_DIVIDER}
          className={classes.divider}
        />
        <div
          data-hook={FiltersDataHooks.FILTERS_WRAPPER}
          className={st(classes.root, {
            direction: isMobile ? 'column' : 'row',
          })}
        >
          {filterViewModels.map((filterViewModel) => (
            <Filter
              key={filterViewModel.id}
              filterViewModel={filterViewModel}
            />
          ))}
        </div>
      </>
    );
  }
  return null;
};

export default Filters;
